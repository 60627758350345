import request from '@/utils/request'

export function enterpriseSearch(data) {
  return request({
    url: '/enterprise/search',
    method: 'post',
    data
  })
}

// 坐标转换 /map/convert
export function convert(data) {
  return request({
    url: '/map/convert',
    method: 'post',
    data
  })
}

export function enterpriseInfo(data = { route: '', credit_no: '' }) {
  return request({
    url: `/enterprise/api`,
    method: 'post',
    data
  })
}

export function searchPark(data = {}) {
  return request({
    url: '/park/query',
    method: 'post',
    data
  })
}


export function searchParkLocation(params = {}) {
  return request({
    url: '/park/location',
    method: 'get',
    params
  })
}


export function getInnerParkCompanies(params = {}) {
  return request({
    url: '/enterprise/nearby',
    method: 'post',
    params: { radius: params.radius, skip: params.skip || 1, longitude: params.longitude, latitude: params.latitude }
  })
}

export function getIndustryReports(params = {}) {
  return request({
    url: '/common/getIndustryReports',
    method: 'get',
    params
  })
}

export function getLocationGeo(params = {}) {
  return request({
    url: '/park/getLocationGeo',
    method: 'get',
    params
  })
}


export function getLocationByParkId(params = {}) {
  return request({
    url: '/park/location',
    method: 'get',
    params
  })
}

export function getEnterprise(data = {}) {
  return request({
    url: '/enterprise/list',
    method: 'post',
    data
  })
}

export function getListByPolygon(data = {}) {
  return request({
    url: '/enterprise/listByPolygon',
    method: 'post',
    data
  })
}

export function getPackAges(params = {}) {
  return request({
    url: '/vip/packages',
    method: 'get',
    params
  })
}

export function getState(params = {}) {
  return request({
    url: '/vip/state',
    method: 'get',
    params
  })
}


export function getAttributeByLocation(params = {}) {
  return request({
    url: '/map/getAttributeByLocation',
    method: 'get',
    params
  })
}

export function getAttributeData(data = {}) {
  return request({
    url: '/sheet/getData',
    method: 'post',
    data
  })
}


export function sheetExportExcel(data = {}) {
  return request({
    url: '/sheet/exportExcel',
    method: 'post',
    data
  })
}


export function saveEstimate(data = {}) {
  return request({
    url: '/estimate/save',
    method: 'post',
    data
  })
}


export function listEstimate(params = {}) {
  return request({
    url: '/estimate/list',
    method: 'get',
    params
  })
}


export function getEstimateOne(params = {}) {
  return request({
    url: '/estimate/get_estimate_data',
    method: 'get',
    params
  })
}


export function createGroup(params = {}) {
  return request({
    url: '/estimate/create_group',
    method: 'get',
    params
  })
}

export function deleteCeSuan(data = {}) {
  return request({
    url: '/estimate/delete',
    method: 'post',
    data
  })
}


export function updateEstimate(data = {}) {
  return request({
    url: '/estimate/update_estimate_data',
    method: 'post',
    data
  })
}


export function updateGroup(data = {}) {
  return request({
    url: '/estimate/update_group',
    method: 'post',
    data
  })
}

export function createNewProject(data = {}) {
  return request({
    url: '/project/createProject',
    method: 'post',
    data
  })
}

export function updateProject(data = {}) {
  return request({
    url: '/project/editProject',
    method: 'post',
    data
  })
}

export function getProjectDetail(data = {}) {
  return request({
    url: '/project/projectDetail',
    method: 'post',
    data
  })
}


export function getNewProjectList(data = {}) {
  return request({
    url: '/project/list',
    method: 'post',
    data
  })
}


export function delNewGroup(data = {}) {
  return request({
    url: '/estimate/delete_group',
    method: 'post',
    data
  })
}


export function update_group_name(data = {}) {
  return request({
    url: '/estimate/update_group_name',
    method: 'post',
    data
  })
}

export function doDeleteProjectFile(data = {}) {
  return request({
    url: '/project/deleteProjectFile',
    method: 'post',
    data
  })
}

export function createProjectGroup(data = {}) {
  return request({
    url: '/project/createGroup',
    method: 'post',
    data
  })
}


export function updateProjectGroup(data = {}) {
  return request({
    url: '/project/editGroup',
    method: 'post',
    data
  })
}


export function delProject(data = {}) {
  return request({
    url: '/project/deleteProject',
    method: 'post',
    data
  })
}


export function deleteProjectGroup(data = {}) {
  return request({
    url: '/project/deleteGroup',
    method: 'post',
    data
  })
}


export function upDoProjectExportDxf(data = {}) {
  return request({
    url: '/export_dxf',
    method: 'post',
    data
  })
}

export function doExportReportReport(data = {}) {
  return request({
    url: '/project/exportReport',
    method: 'post',
    data
  })
}

export function doReportData(data = {}) {
  return request({
    url: '/project/getReportData',
    method: 'post',
    data
  })
}


export function exporSheetData(data = {}) {
  return request({
    url: '/sheet/exporReportData',
    method: 'post',
    data
  })
}


export function doLayerSearch(data = {}) {
  return request({
    url: '/map/layerSearch',
    method: 'post',
    data
  })
}

// 活动配置
export function getActivityConfig(params = {}) {
  return request({
    url: '/activity/config',
    method: 'get',
    params
  })
}



