import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _959c57da = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _2b5f8d80 = () => interopDefault(import('../pages/bonusPoints/index.vue' /* webpackChunkName: "pages/bonusPoints/index" */))
const _205ab44e = () => interopDefault(import('../pages/enterprise/index.vue' /* webpackChunkName: "pages/enterprise/index" */))
const _eef366fc = () => interopDefault(import('../pages/findings/index.vue' /* webpackChunkName: "pages/findings/index" */))
const _702cbf7a = () => interopDefault(import('../pages/map/index.vue' /* webpackChunkName: "pages/map/index" */))
const _14c9e7da = () => interopDefault(import('../pages/service/index.vue' /* webpackChunkName: "pages/service/index" */))
const _557659f5 = () => interopDefault(import('../pages/user-center/index.vue' /* webpackChunkName: "pages/user-center/index" */))
const _f587b11c = () => interopDefault(import('../pages/enterpriseDetail/_id.vue' /* webpackChunkName: "pages/enterpriseDetail/_id" */))
const _53c2d1ed = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _959c57da,
    meta: {"title":"404","hidden":true,"sort":8},
    name: "404"
  }, {
    path: "/bonusPoints",
    component: _2b5f8d80,
    meta: {"title":"积分活动","hidden":true,"sort":7},
    name: "bonusPoints"
  }, {
    path: "/enterprise",
    component: _205ab44e,
    meta: {"title":"企业快查","sort":2},
    name: "enterprise"
  }, {
    path: "/findings",
    component: _eef366fc,
    meta: {"title":"产业研究","sort":5},
    name: "findings"
  }, {
    path: "/map",
    component: _702cbf7a,
    meta: {"title":"产业地图","sort":3},
    name: "map"
  }, {
    path: "/service",
    component: _14c9e7da,
    meta: {"title":"产业服务","sort":4},
    name: "service"
  }, {
    path: "/user-center",
    component: _557659f5,
    meta: {"title":"服务中心","hidden":true,"sort":6},
    name: "user-center"
  }, {
    path: "/enterpriseDetail/:id?",
    component: _f587b11c,
    meta: {"title":"企业详情","hidden":true,"sort":7},
    name: "enterpriseDetail-id"
  }, {
    path: "/",
    component: _53c2d1ed,
    meta: {"title":"首页","sort":0},
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
