// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/vip-pic.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c_333[data-v-25e18b7e]{color:#333;margin-bottom:15px}.vip-txt[data-v-25e18b7e]{font-size:18px;font-family:Source Han Sans CN-Bold,Source Han Sans CN;font-weight:bold;color:#9e5a32}.vip-alert[data-v-25e18b7e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:center;background-repeat:no-repeat;background-size:cover;text-align:center}.content-box[data-v-25e18b7e]{min-height:350px;background-color:#fff;border-radius:3px;margin-top:23px;padding-left:35px;padding-right:35px;margin-right:-15px}.text-left[data-v-25e18b7e]{text-align:left}.vip-box[data-v-25e18b7e]:hover{border:2px solid #f50}.vip-box[data-v-25e18b7e]{width:130px;height:90px;border-radius:3px 3px 3px 3px;display:flex;flex-direction:column;align-items:center;justify-content:center;box-shadow:0px 2px 3px 1px rgba(87,90,107,.1);border:2px solid transparent;transition:all ease .4s;cursor:pointer;margin-bottom:20px}.price[data-v-25e18b7e]{font-size:16px;font-family:Source Han Sans CN-Bold,Source Han Sans CN;font-weight:bold;color:#f50}.ext-price[data-v-25e18b7e]{font-size:12px;font-family:Source Han Sans CN-Bold,Source Han Sans CN;color:#aaacb3;text-decoration:line-through}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
