import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/passport/smsLogin',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: '/user/info',
    method: 'get'
  })
}

export function doSms(data) {
  return request({
    url: '/passport/sendsms',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/passport/logout',
    method: 'post'
  })
}

export function miniproGramCode() {
  return request({
    url: '/passport/miniprogramCode',
    method: 'get'
  })
}


export function qrLogin(params) {
  return request({
    url: '/passport/QrLogin',
    method: 'get',
    params
  })
}

export function getPayPriceUrl(params={}) {
  return request({
    url: '/vip/payment_qrcode',
    method: 'get',
    params
  })
}

export function createOrder(data={}) {
  return request({
    url: '/vip/create_order',
    method: 'post',
    data
  })
}


export function getBd092cj02(params={}) {
  return request({
    url: '/map/bd092cj02',
    method: 'get',
    params
  })
}

export function onSubmitContactInfo(data={}) {
  return request({
    url: '/user/submitContactInfo',
    method: 'post',
    data
  })
}

export function getOrders(params={}) {
  return request({
    url: '/vip/orders',
    method: 'get',
    params
  })
}

export function getLocationByPassPort(params={}) {
  return request({
    url: '/passport/getLocation',
    method: 'get',
    params
  })
}



