
export default {
  name : 'SvgIcon',
  props : {
    iconClass : {
      type : String,
      required : true
    },
    className : {
      type : String,
      default : ''
    }
  },
  computed : {
    iconName() {
      return `#icon-${this.iconClass}`
    },
    svgClass() {
      if ( this.className ) {
        return 'svg-icon ' + this.className
      } else {
        return 'svg-icon'
      }
    },
    styleExternalIcon() {
      return {
        mask : `url(${this.iconClass}) no-repeat 50% 50%`,
        '-webkit-mask' : `url(${this.iconClass}) no-repeat 50% 50%`
      }
    }
  }
}
