
import vipContent from './vip-content'
export default {
  components: {
    vipContent
  },
  props: {
  },
  data() {
    return {
        vipcenterDialogVisible: false,
        dataList: [
        ]
    }
  },
  methods: {
    isVip() {
        this.vipcenterDialogVisible = false
    },
    showPop() {                
        this.vipcenterDialogVisible = true
    },
  }
}
