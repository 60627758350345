
import { getPackAges, getState } from '@/api/enterprise'
import { getPayPriceUrl, createOrder } from '@/api/user'
import axios from 'axios'
export default {
  components: {
  },
  props: {
    isDialog: {
        type: Boolean,
        default: true
    }
  },
  data() {
    return {
        isLoading: false,
        activeIndex: 0,
        vipcenterDialogVisible: false,
        moneyDialog: false,
        payurl: '',
        orderInfo: {},
        dataList: [
        ]
    }
  },
  mounted() {
    this.fetch()
  },
  methods: { 
    chooseItem(i) {
        this.activeIndex = i
    },
    async doOrder(packageId) {
        const res = await createOrder({package_id:packageId})        
        this.orderInfo = res.data        
    },   
    async getPrice(packageId) { 
        this.isLoading = true       
        await this.doOrder(packageId)        
        const res = await getPayPriceUrl({no: this.orderInfo.no})
        this.payurl =  res.data.code_url
        this.moneyDialog = true
        this.isLoading = false
        this.$nextTick( () => {
            new QRCode(this.$refs.qrcodeDom, {
                    text: res.data.code_url, // 扫码后页面地址
                    width: 150, // 二维码宽度
                    height: 150, // 二维码高度
                    colorDark: "#000000", // 二维码颜色
                    colorLight: "#ffffff", // 背景颜色
                    correctLevel: QRCode.CorrectLevel.H, // 校正水准
            });  
            this.checkIsPay(this.orderInfo.no)
        })
                   
    },
    close() {
        this.moneyDialog = false
        this.openCheck && clearInterval(this.openCheck)
        this.openCheck = null
    },
    // 是否支付
    checkIsPay(no) {
        this.openCheck && clearInterval(this.openCheck)
        this.openCheck = null
        
        this.openCheck = setInterval( async () => {
                if (this.openCheck) {
                    const res = await getState({no})
                    if (res.data.is_pay) {
                        this.close()
                        this.$store.dispatch('user/getInfo')
                        this.$emit('doVip', true)
                    }
                }
        }, 1500)
    },
    async fetch() {
        const res = await getPackAges({})
        this.dataList = res.data
        // this.getPrice(dataList[activeIndex]['no'])
    },
  }
}
